import React, { lazy } from "react"
import { Navigate } from "react-router-dom"

// Pages Component
import Chat from "../pages/Chat/Chat"

// File Manager
import FileManager from "../pages/FileManager/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// //Tasks
import TasksList from "../pages/Tasks/tasks-list"
import TasksCreate from "../pages/Tasks/tasks-create"
import TasksKanban from "../pages/Tasks/tasks-kanban"

// //Projects
import ProjectsGrid from "../pages/Projects/projects-grid"
import ProjectsList from "../pages/Projects/projects-list"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
import ProjectsCreate from "../pages/Projects/projects-create"

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceDetails/index"
import Loadable from "components/Common/Loadable"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
// import Dashboard from "../pages/Dashboard/index";
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard/index")))
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"
import Blog from "../pages/Dashboard-Blog/index"
import DashboardJob from "../pages/DashboardJob/index"

//Crypto
import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
import CryptoExchange from "../pages/Crypto/crypto-exchange"
import CryptoLending from "../pages/Crypto/crypto-lending"
import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

//Job
import JobGrid from "../pages/JobPages/JobGrid/index"
import JobDetails from "../pages/JobPages/JobDetails"
import JobCategories from "../pages/JobPages/JobCategories"
import JobList from "../pages/JobPages/JobList"
import ApplyJobs from "../pages/JobPages/ApplyJobs/index"
import CandidateList from "../pages/JobPages/CandidateList"
import CandidateOverview from "../pages/JobPages/CandidateOverview"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiOffCanvas from "pages/Ui/UiOffCanvas"
import UiUtilitie from "../pages/Ui/UiUtilitie"
import UiPlaceholders from "../pages/Ui/UiPlaceholders"
import UiToasts from "../pages/Ui/UiToast"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import ReceiptOrderView from "pages/ReceiptOrder/ReceiptOrderView"
import Collection from "pages/Master/Collection"
import Gst from "pages/Master/Gst"
import ProCollection from "pages/ProCollection"
import AddCollection from "pages/ProCollection/AddCollection"
import EditCollection from "pages/ProCollection/EditCollection"
import POList from "pages/Procurements/POList"
import ReadyItem from "pages/Procurements/ReadyItem"
import FromOrder from "pages/Procurements/FromOrder"
import Reports from "pages/Reports"
import CustomerPaymentFailedReport from "pages/Reports/CustomePaymentFailed"








//Contacts
const ContactsGrid = Loadable(
  lazy(() => import("../pages/Contacts/contacts-grid"))
)
const ContactsList = Loadable(
  lazy(() => import("../pages/Contacts/ContactList/contacts-list"))
)
const ContactsProfile = Loadable(
  lazy(() => import("../pages/Contacts/ContactsProfile/contacts-profile"))
)
const Category = Loadable(lazy(() => import("pages/Master/Category")))
const SubCategory = Loadable(lazy(() => import("pages/Master/SubCategory")))
const VendorType = Loadable(lazy(() => import("pages/Master/VendorType")))
const PaymentTerm = Loadable(lazy(() => import("pages/Master/PaymentTerm")))
const PaymentMethod = Loadable(lazy(() => import("pages/Master/PaymentMethod")))
const ClientList = Loadable(lazy(() => import("pages/ClientManagement/Client")))
const AddClient = Loadable(
  lazy(() => import("pages/ClientManagement/Client/Add"))
)
const EditClient = Loadable(
  lazy(() => import("pages/ClientManagement/Client/Edit"))
)
const ProductList = Loadable(lazy(() => import("pages/Product/ProductList")))
const AddProduct = Loadable(lazy(() => import("pages/Product/ProductList/Add")))
const EditProduct = Loadable(
  lazy(() => import("pages/Product/ProductList/Edit"))
)
const VendorList = Loadable(lazy(() => import("pages/Vendor/VendorList")))
const AddVendor = Loadable(lazy(() => import("pages/Vendor/VendorList/Add")))
// import EditVendor from "pages/Vendor/VendorList/Edit";
const EditVendor = Loadable(lazy(() => import("pages/Vendor/VendorList/Edit")))
const ShippingProviderList = Loadable(
  lazy(() => import("pages/ShippingProvider/ShippingProviderList"))
)
const AddShippingProvider = Loadable(
  lazy(() => import("pages/ShippingProvider/ShippingProviderList/Add"))
)
const EditShippingProvider = Loadable(
  lazy(() => import("pages/ShippingProvider/ShippingProviderList/Edit"))
)
const AddMediaType = Loadable(
  lazy(() => import("pages/Product/ProductList/AddMediaType"))
)
const PaymentAccountList = Loadable(
  lazy(() => import("pages/Master/PaymentAccount"))
)
const AddPaymentAccount = Loadable(
  lazy(() => import("pages/Master/PaymentAccount/Add"))
)
const EditPaymentAccount = Loadable(
  lazy(() => import("pages/Master/PaymentAccount/Edit"))
)
const EditProfile = Loadable(
  lazy(() => import("pages/Authentication/Edit-Profile"))
)
const ChangePassword = Loadable(
  lazy(() => import("pages/Authentication/ChangePassword"))
)
const AddUser = Loadable(lazy(() => import("pages/Master/User/AddUser")))
const User = Loadable(lazy(() => import("pages/Master/User")))
const EditUser = Loadable(lazy(() => import("pages/Master/User/EditUser")))
const ViewUser = Loadable(lazy(() => import("pages/Master/User/View")))
const Modules = Loadable(lazy(() => import("pages//Master/Acl/Modules")))
const Role = Loadable(lazy(() => import("pages/Master/Acl/Roles")))
const AdRole = Loadable(lazy(() => import("pages/Master/Acl/Roles/AdRole")))
const SubModule = Loadable(lazy(() => import("pages/Master/Acl/SubModules")))
const EditRole = Loadable(lazy(() => import("pages/Master/Acl/Roles/EditRole")))
const Deduction = Loadable(lazy(() => import("pages/Master/Decution")))

// Client
const ClientCart = Loadable(lazy(()=>import('components/Client/Cart')))
const OrderPage = Loadable(lazy(()=>import('pages/orders')))
const OrderView = Loadable(lazy(()=>import('pages/orders/view')))
const PaymentOrderAdd = Loadable(lazy(()=>import("pages/PaymentOrder/Add")))
const PaymentOrderEdit = Loadable(lazy(()=>import("pages/PaymentOrder/Edit")))
const PaymentOrderList = Loadable(lazy(()=>import("pages/PaymentOrder")))
const DeductionSetting = Loadable(lazy(()=>import("pages/Master/DeductionSetting")))
const PurchaseOrder = Loadable(lazy(()=>import("pages/PurchaseOrder")))
const PurchaseOrderEdit = Loadable(lazy(()=>import("pages/PurchaseOrder/Edit")))
const PurchaseOrderAdd = Loadable(lazy(()=>import("pages/PurchaseOrder/Add")))
const ReceiptOrderList =  Loadable(lazy(()=>import("pages/ReceiptOrder")))
const ReceiptOrderAdd =  Loadable(lazy(()=>import("pages/ReceiptOrder/Add")))
const ReceiptOrderEdit =  Loadable(lazy(()=>import("pages/ReceiptOrder/Edit")))
const SubsubCategory =  Loadable(lazy(()=>import("pages/Master/Subsubcategory")))
const ProcurementOrder =  Loadable(lazy(()=>import("pages/Procurements/ProcurementOrder")))
const ProcurementList =   Loadable(lazy(()=>import("pages/Procurements/ProcurementList")))
const ProcurementReadyItem =   Loadable(lazy(()=>import("pages/Procurements/ProcurementReadyItem")))
const EditProcurementOrder = Loadable(lazy(()=>import("pages/Procurements/ProcurementOrder/Edit")))
const Sales     = Loadable(lazy(()=>import("pages/Reports/Sales")))
const TdsReport = Loadable(lazy(()=>import("pages/Reports/TdsReport")))
const GstReport = Loadable(lazy(()=>import("pages/Reports/GstReport")))
const ProfitCostReport = Loadable(lazy(()=>import("pages/Reports/ProfitCostReport")))
const ProductReport  = Loadable(lazy(()=>import("pages/Reports/ProductReport")))
const SalesmanReport = Loadable(lazy(()=>import("pages/Reports/SalesmanReport")))
const CustomerReport = Loadable(lazy(()=>import("pages/Reports/CustomerReport")))
const ProcurementOrderPaymentAdd = Loadable(lazy(()=>import("pages/ProcurementPayment/Add")))
const ProcurementPaymentList = Loadable(lazy(()=>import("pages/ProcurementPayment")))
const ProcurementPaymenView = Loadable(lazy(()=>import("pages/ProcurementPayment/ProcurementPaymenView")))
const GstPartialReport = Loadable(lazy(()=>import("pages/Reports/GstPartialReport")))

const authProtectedRoutes = [
  { path: "/orders", component: <OrderPage /> },
  { path: "/orders/add", component: <ClientCart /> },
  { path: "/orders/:id", component: <ClientCart /> },
  { path: "/orders/view/:id", component: <OrderView /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard-saas", component: <DashboardSaas /> },
  { path: "/dashboard-crypto", component: <DashboardCrypto /> },
  { path: "/blog", component: <Blog /> },
  { path: "/dashboard-job", component: <DashboardJob /> },

  //Crypto
  { path: "/crypto-wallet", component: <CryptoWallet /> },
  { path: "/crypto-buy-sell", component: <CryptoBuySell /> },
  { path: "/crypto-exchange", component: <CryptoExchange /> },
  { path: "/crypto-lending", component: <CryptoLending /> },
  { path: "/crypto-orders", component: <CryptoOrders /> },
  { path: "/crypto-kyc-application", component: <CryptoKYCApplication /> },

  //chat
  { path: "/chat", component: <Chat /> },

  //File Manager
  { path: "/apps-filemanager", component: <FileManager /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  // { path: "/profile", component: <UserProfile /> },

  //Ecommerce
  {
    path: "/ecommerce-product-detail/:id",
    component: <EcommerceProductDetail />,
  },
  { path: "/ecommerce-products", component: <EcommerceProducts /> },
  { path: "/ecommerce-orders", component: <EcommerceOrders /> },
  { path: "/ecommerce-customers", component: <EcommerceCustomers /> },
  { path: "/ecommerce-cart", component: <EcommerceCart /> },
  { path: "/ecommerce-checkout", component: <EcommerceCheckout /> },
  { path: "/ecommerce-shops", component: <EcommerceShops /> },
  { path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read/:id?", component: <EmailRead /> },
  { path: "/email-template-basic", component: <EmailBasicTemplte /> },
  { path: "/email-template-alert", component: <EmailAlertTemplte /> },
  { path: "/email-template-billing", component: <EmailTemplateBilling /> },

  //Invoices
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail/:id", component: <InvoiceDetail /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },

  // Tasks
  { path: "/tasks-list", component: <TasksList /> },
  { path: "/tasks-create", component: <TasksCreate /> },
  { path: "/tasks-kanban", component: <TasksKanban /> },

  //Projects
  { path: "/projects-grid", component: <ProjectsGrid /> },
  { path: "/projects-list", component: <ProjectsList /> },
  { path: "/projects-overview", component: <ProjectsOverview /> },
  { path: "/projects-overview/:id", component: <ProjectsOverview /> },
  { path: "/projects-create", component: <ProjectsCreate /> },

  //Blog
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-details", component: <BlogDetails /> },

  //job
  { path: "/job-grid", component: <JobGrid /> },
  { path: "/job-details", component: <JobDetails /> },
  { path: "/job-categories", component: <JobCategories /> },
  { path: "/job-list", component: <JobList /> },
  { path: "/job-apply", component: <ApplyJobs /> },
  { path: "/candidate-list", component: <CandidateList /> },
  { path: "/candidate-overview", component: <CandidateOverview /> },

  // Contacts
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },

  // Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-layouts", component: <FormLayouts /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-notifications", component: <UiNotifications /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-utilities", component: <UiUtilitie /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-toasts", component: <UiToasts /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  // Master

  { path: "/category", component: <Category /> },
  { path: "/sub-category", component: <SubCategory /> },
  { path: "/sub-sub-category", component: <SubsubCategory /> },

  { path: "/vendor-type", component: <VendorType /> },
  { path: "/payment-term", component: <PaymentTerm /> },
  { path: "/payment-method", component: <PaymentMethod /> },
  { path: "/collection", component: <Collection /> },
  { path: "/gst", component: <Gst /> },

  { path: "/payment-account", component: <PaymentAccountList /> },
  { path: "/payment-account/add", component: <AddPaymentAccount /> },
  { path: "/payment-account/edit/:id", component: <EditPaymentAccount /> },

  { path: "/deduction", component: <Deduction /> },
  { path: "/setting", component: <DeductionSetting /> },

  //Client Management

  { path: "/client", component: <ClientList /> },
  { path: "/client/add", component: <AddClient /> },
  { path: "/client/edit/:id", component: <EditClient /> },

  { path: "/product", component: <ProductList /> },
  { path: "/product/add", component: <AddProduct /> },
  { path: "/product/edit/:id", component: <EditProduct /> },
  { path: "/product/media/:id", component: <AddMediaType /> },

  { path: "/shipping-provider", component: <ShippingProviderList /> },
  // { path: "/shipping-provider/add", component: <AddShippingProvider /> },
  // { path: "/shipping-provider/edit/:id", component: <EditShippingProvider /> },

  { path: "/vendor", component: <VendorList /> },
  { path: "/vendor/add", component: <AddVendor /> },
  { path: "/vendor/edit/:id", component: <EditVendor /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  // Profile
  { path: "/profile", component: <EditProfile /> },
  { path: "/change-password", component: <ChangePassword /> },

  //User manage
  { path: "/manage-user", component: <User /> },
  { path: "/manage-user/add", component: <AddUser /> },
  { path: "/manage-user/edit", component: <EditUser /> },
  { path: "/manage-user/view", component: <ViewUser /> },

  // ACL_Modules

  { path: "/modules", component: <Modules /> },
  { path: "/sub-modules", component: <SubModule /> },

  { path: "/role", component: <Role /> },
  { path: "/role/add", component: <AdRole /> },
  { path: "/role/edit", component: <EditRole /> },

  // Collections

  { path: "/collections", component: <ProCollection /> },
  { path: "/addCollection", component: <AddCollection /> },
  { path: "/editCollection", component: <EditCollection /> },

  //Payment Order -old

  { path: "/receipt-order-old", component: <PaymentOrderList /> },
  { path: "/receipt-order-old/add", component: <PaymentOrderAdd /> },
  { path: "/receipt-order-old/edit/:id", component: <PaymentOrderEdit /> },

  //Receipt Order

  { path: "/receipt-order", component: <ReceiptOrderList /> },
  { path: "/receipt-order/add", component: <ReceiptOrderAdd /> },
  { path: "/receipt-order/edit/:id", component: <ReceiptOrderEdit /> },
  { path: "/receipt-order/view/:id", component: <ReceiptOrderView /> },

  { path: "/purchase-order", component: <PurchaseOrder /> },
  { path: "/purchase-order/add", component: <PurchaseOrderAdd /> },
  { path: "/purchase-order/edit/:id", component: <PurchaseOrderEdit /> },

  // Procurement
  { path: "/polist", component: <POList /> },
  { path: "/ready-item", component: <ReadyItem /> },
  { path: "/from-order", component: <FromOrder /> },
  { path: "/procurement-order", component: <ProcurementOrder /> },
  { path: "/procurement", component: <ProcurementList /> },
  { path: "/procurement-ready-item", component: <ProcurementReadyItem /> },


  { path:"/purchase-order",component:<PurchaseOrder/>},
  { path:"/purchase-order/add",component:<PurchaseOrderAdd/>},
  { path:"/purchase-order/edit/:id",component:<PurchaseOrderEdit/>},
  
// Procurement
{ path:"/polist",component:<POList/>},
{ path:"/ready-item",component:<ReadyItem/>},
{ path:"/from-order",component:<FromOrder/>},
{ path:"/procurement-order",component:<ProcurementOrder/>},
{ path:"/procurement-order/edit/:id",component:<EditProcurementOrder/>},
{ path:"/procurement",component:<ProcurementList/>},
{ path:"/procurement-ready-item",component:<ProcurementReadyItem/>},
{ path:"/procurement-ready-item/:orderId/:lineItemId",component:<ProcurementReadyItem/>}, 

//Procurement Payment
{ path:"/procurement-payment-order",component:<ProcurementPaymentList/>},
{ path:"/procurement-payment-order/add",component:<ProcurementOrderPaymentAdd/>}, 
{ path:"/procurement-payment-order/view/:id",component:<ProcurementPaymenView/>},

{ path: "/sales-report", component: <Sales /> },
{ path: "/gst-report", component: <GstReport /> },
{ path: "/partial-gst-report", component: <GstPartialReport /> },
{ path: "/tds-report", component: <TdsReport /> },
{ path: "/profit-cost-report", component: <ProfitCostReport /> },
{ path: "/product-report", component: <ProductReport /> },
{ path: "/customer-report", component: <CustomerReport /> },
// { path: "/sales-list", component: <Reports /> },
{ path: "/salesman-report", component: <SalesmanReport /> },
{ path: "/payment-failed-report", component: <CustomerPaymentFailedReport /> },

];



const noAuthRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-forgot-pwd-2", component: <ForgetPwd2 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
]

export { authProtectedRoutes, publicRoutes, noAuthRoutes }
