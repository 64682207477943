import React, { useEffect, useMemo, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
//import components

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  CardTitle,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Table,
  Fade,
  Label,
  FormFeedback,
  Badge,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link } from "react-router-dom"
import {
  deleteClient,
  getClientList,
  getClientType,
} from "services/ClientManagementServices"
import Paginations from "components/Common/Pagination"
import moment from "moment"
import { isEmpty } from "lodash"
import SearchPage from "components/Common/SearchPage"
import { objectCreateAttributeHelper } from "helpers/globalHelper"
import customStyles from "helpers/custom_Styles"
import DeleteModal from "components/Common/DeleteModal"
import TableContainer from "components/Common/TableContainer"
import Breadcrumb from "components/Common/Breadcrumb"
import DatePicker from "components/Common/DatePicker"
import { getUsers } from "services/UserServices"
import {
  getSalesSearchList,
  getSalesSearchGraph,
  getGstTotal as getGSTReportData,
  getPaymentFailedList, // Rename for clarity
} from "services/ReportsServices"
import Apexchart1 from "./Apexchart1"
import { set } from "store"

const CustomerPaymentFailedReport = () => {
  //meta title
  document.title = `Report- Payment Failed List|  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`

  const dummyOrderIds = [
    { value: "ORD123", label: "ORD123" },
    { value: "ORD124", label: "ORD124" },
    { value: "ORD125", label: "ORD125" },
  ]
  const reportOptions = [
    { value: "list", label: "List" },
    { value: "summary", label: "Summary" },
  ]
  // const dummyAcYears = [
  //   { value: "2024-25", label: "2024-25" },
  //   { value: "2025-26", label: "2025-26" },
  // ]

  //   const dummySalesmen = [
  //     { value: "John Doe", label: "John Doe" },
  //     { value: "Jane Smith", label: "Jane Smith" },
  //     { value: "Chris Johnson", label: "Chris Johnson" },
  //   ]

  const periodOptions = [
    // { value: "", label: "Select" },
    { value: "7", label: "Last 1 Week" },
    { value: "30", label: "Last 30 Days" },
    { value: "90", label: "Last 90 Days" },
    { value: "180", label: "Last 6 Months" },
  ]

  const periodOptions1 = [
    // { value: "", label: "Select" },
    { value: "1", label: "Date Range" },

    { value: "2", label: "Period" },
    { value: "3", label: "A/C Year" },
  ]
  const [choose, setChoose] = useState(null)
  const [meta, setMeta] = useState()
  const [dummySalesmen, setDummySalesmen] = useState([])
  const [clients, setClients] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [totalCount, setTotalCount] = useState(0)
  const [job, setJob] = useState(null)
  const [search, setSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState([])
  const [selectedPeriod, setSelectedPeriod] = useState(null)
  const [saleSearchData, setSaleSearchData] = useState([])
  const [searchFilter, setSearchFilter] = useState([])
  const [searchFilter1, setSearchFilter1] = useState([])
  const [clientTypeList, setClientTypeList] = useState([])
  const [reportType, setReportType] = useState("list")
  const [graphData, setGraphData] = React.useState([])
  const [trigger, setTrigger] = useState(0)
  const [gstData, setGstData] = useState([])
  const [rest, setRest] = useState(0)
  const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState(true)
  const [advanceSearch, setAdvanceSearch] = useState({
    order_start_date: "",
    order_end_date: "",
    client_id: null,
    order_no: null,
    // acYear: null,
    sales_man_id: null,
    // period: null,
    order_type: null,
  })

  const generateAcYears = () => {
    const currentYear = moment().year() // Get current year
    const startYear = 2024 // Starting year for A/C year generation
    const years = []

    for (let year = startYear; year <= currentYear + 1; year++) {
      const acYear = `${year}-${(year + 1).toString().slice(-2)}` // Generate year range (e.g., 2024-25)
      years.push({ value: acYear, label: acYear }) // Push value and label to options
    }

    return years
  }
  const dummyAcYears = generateAcYears() // Generate dynamic options

  const handlePeriod = id => {
    console.log("Selected Period ID:", id)

    // Calculate today's date
    const today = moment().format("YYYY-MM-DD")
    let startDate = null

    // Set start date based on selected period
    switch (id) {
      case "7": // Last 7 days
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD")
        break
      case "15": // Last 15 days
        startDate = moment().subtract(15, "days").format("YYYY-MM-DD")
        break
      case "30": // Last 30 days
        startDate = moment().subtract(30, "days").format("YYYY-MM-DD")
        break
      case "90": // Last 90 days
        startDate = moment().subtract(90, "days").format("YYYY-MM-DD")
        break
      default:
        startDate = null
    }

    setAdvanceSearch({
      ...advanceSearch,
      order_start_date: startDate,
      order_end_date: today,
    })

    setSelectedPeriod(id) // Update the selected period state
  }

  const formatAdvanceSearchData = () => ({
    order_start_date: advanceSearch.search
      ? advanceSearch.order_end_date.trim()
      : null,
    order_end_date: advanceSearch.search
      ? advanceSearch.order_end_date.trim()
      : null,
    order_type: advanceSearch.order_type
      ? advanceSearch.order_type.trim()
      : null,
    client_id: advanceSearch.clientTypeId || null,
    order_no: advanceSearch.orderId || null,
    // acYear: advanceSearch.acYear || null,
    sales_man_id: advanceSearch.salesman || null,
    // period: advanceSearch.period || null,
  })

  useEffect(() => {
    getSalesManList()
    getClients()

    getCientTypeRecord()
  }, [])

  useEffect(() => {
    getClientListRecord(advanceSearch)
  }, [page, limit, rest])
  const getSalesManList = async () => {
    try {
      let params = {
        status: 1,
        // sortDirection: "asc",
        user_type: 2,
      }

      await getUsers(params)
        .then(response => {
          console.log("Response Data:", response?.data)
          // If response is an array and you want to transform it
          if (Array.isArray(response?.data)) {
            let temp = response?.data?.map(item => ({
              label: item.first_name,
              value: item.id,
            }))
            // Log the transformed data
            console.log("Transformed Data:", temp)
            setDummySalesmen(temp)
            // setVendorTypeList(temp); // Uncomment this if you want to set the vendor list
          }
        })
        .catch(err => {
          console.error("Error fetching users:", err)
        })
    } catch (error) {
      console.error("Error in getSalesManList:", error)
    }
  }
  const clientList = clients.map(client => ({
    value: client.id, // Unique identifier for client
    label: client.client_name, // Display name for the dropdown
  }))

  const getClients = async () => {
    try {
      let params = {
        status: "1",
      }

      await getClientList(params)
        .then(response => {
          console.log(response, "Respo")

          if (response?.data) {
            const { meta, data } = response
            setClients(data ?? [])
            setTotalCount(meta?.itemCount)
          }
          setLoading(false)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.error("Error in getSalesManList:", error)
    }
  }

  const getClientListRecord = async (
    advanceSearch = false,
    resetAdvanceSearch = false
  ) => {
    try {
      // Select Date rage
      // if (choose === "1") {
      //   if (
      //     (advanceSearch.order_start_date && !advanceSearch.order_end_date) ||
      //     (!advanceSearch.order_start_date && advanceSearch.order_end_date)
      //   ) {
      //     toast.error("Please provide both start and end dates.");
      //     return; // Stop execution
      //   }
      // }

      if (resetAdvanceSearch) {
        setAdvanceSearch({
          order_start_date: "",
          client_id: null,
          order_no: null,
          acYear: null,
          sales_man_id: null,
          //   period: null,
          order_start_date: null,
          order_end_date: null,
        })
        setReportType("list")
      }

      let params = {
        page: page,
        limit: limit,
        sortDirection: "asc",
      }

      // Object.keys(advanceSearch).forEach(key => {
      //   const value = advanceSearch[key]
      //   // Exclude "open" and other unwanted keys
      //   if (key !== "open" && value !== null && value !== "") {
      //     params[key] = value
      //   }
      // })
      let params1 = {
        page: page,
        limit: limit,
        sortDirection: "asc",
      }

      Object.keys(advanceSearch).forEach(key => {
        const value = advanceSearch[key]

        // Exclude "open" and other unwanted keys
        if (key !== "open" && value !== null && value !== "") {
          // Additional validation for date fields
          if (
            (key === "order_start_date" || key === "order_end_date") &&
            isNaN(new Date(value).getTime())
          ) {
            // Skip invalid dates
            return
          }
          params[key] = value
          params1[key] = value
        }
      })

      //   if (advanceSearch) {
      //     // const advanceSearchParams = formatAdvanceSearchData(advanceSearch); // Format advanceSearch
      //     // params = {
      //     //   ...params,
      //     //   ...advanceSearchParams,
      //     // };
      //     console.log("Calling params advanceSearch:--", advanceSearch);
      //   }

      // if (isAdvanceSearchOpen && reset!=='reset') {
      //     let advanceKey = objectCreateAttributeHelper(searchFilter)
      //     params = { ...params, ...advanceKey }
      // }
      // q
      setLoading(true)
      if (reportType === "list") {
        try {
          const response = await getPaymentFailedList(params)
          console.log(response, "Response for List")
          setSaleSearchData(response?.data)
          setMeta(response?.meta)
          setGraphData()
          setTrigger(0)

          setRest(0)
          setPage(response?.meta?.page)
          setTotalCount(response?.meta?.itemCount)
          // Make another API call here for GST report result
          // const gstResponse = await getGSTReportData(params1)
          // console.log(gstResponse, "GST Report Result")
          // setGstData(gstResponse) // Assuming setGstReportResult is a state updater function
        } catch (error) {
          console.error("Error in List API calls:", error)
        }
      } else if (reportType === "summary") {
        const summaryParams = { ...params }
        delete summaryParams.page
        delete summaryParams.limit
        delete summaryParams.sortDirection

        console.log("Calling summary and params--", summaryParams) // await getSalesSummary(params)
        // await getSalesSearchGraph(summaryParams)
        //   .then(response => {
        //     console.log(response, "Response for Summary--", response)
        //     setGraphData(response)
        //     setSaleSearchData([]) // Reset sale search data
        //     setTrigger(1)
        //   })
        //   .catch(err => console.log(err))
      }

      setLoading(false)
    } catch (error) {
      console.log(error, "error")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = () => {
    setPage(1)
    getClientListRecord(advanceSearch)
    console.log("Advance Search Data:", advanceSearch)
  }
  const getCientTypeRecord = async () => {
    try {
      let params = {
        status: "active",
      }
      await getClientType(params)
        .then(response => {
          console.log(response)
          // setClientTypeRecordList(response)

          let tempData = response

          setClientTypeList(
            tempData.map(item => ({ value: item.id, label: item.type_name })) ??
              []
          )

          setSearchFilter([
            ...initialFilter,
            {
              label: "Client Type",
              value: "",
              placeholder: "Search Client Name",
              type: "select",
              name: "clientTypeId",
              options: tempData.map(item => ({
                value: item.id,
                label: item.type_name,
              })),
              // disable: false
            },
          ])
          setSearchFilter1([
            ...initialFilter,
            {
              label: "Client Type",
              value: "",
              placeholder: "Search Client Name",
              type: "select",
              name: "clientTypeId",
              options: tempData.map(item => ({
                value: item.id,
                label: item.type_name,
              })),
              // disable: false
            },
          ])
        })
        .catch(err => console.log(err))
    } catch (error) {}
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const handleDeletejob = async () => {
    if (job && job.id) {
      try {
        await deleteClient(job.id)
          .then(response => {
            console.log(response)
            // if(response){
            toast.success("Client is deleted successfully.", { autoClose: 500 })
            // }
            // getClientListRecord()
          })
          .catch(err => console.log(err))
      } catch (error) {
        toast.error("Something went wrong.", { autoClose: 500 })
      }

      setDeleteModal(false)
    }
  }

  const handleModal = data => {
    setModal(true)
    let temp = data
    setSelectedItem(temp)
  }

  const toggle = () => setModal(!modal)

  const columns = useMemo(
    () => [
      {
        header: "Sl.No.",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <Link to="" className="text-body fw-bold">
              {(page - 1) * limit + (cellProps.row?.index + 1)}
            </Link>
          )
        },
      },
      {
        header: "Customer Name",
        accessorKey: "client_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   header: "Phone No",
      //   accessorKey: "",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Order No",
        accessorKey: "order_no",
        enableColumnFilter: false,
        enableSorting: false,
      },
    
      {
        header: "Order date ",
        accessorKey: "order_date",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ margin: 0 }}>
                {moment(cellProps.row.original?.order_date).format(
                  "DD-MM-YYYY"
                )}
              </p>
              {/* <p style={{ margin: 0 }}>
                {moment(cellProps.row.original?.ordered_delivery_date).format(
                  "DD-MM-YYYY"
                )}
              </p> */}
            </div>
          )
          return
        },
      },
      {
        header: "Payment Terms",
        accessorKey: "payment_term_name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          const { payment_term_name, payment_term_for, payment_term_days } = row?.original;
      
          // Concatenate terms in the required format
          return `${payment_term_name}`;

          // return `${payment_term_name} ${payment_term_for} ${payment_term_days} days`;
        },
      },
      {
        header: () => (
          <div style={{ textAlign: "right", width: "100%" }}>Order Amount</div>
        ),
        accessorKey: "net_order_amount",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue }) => {
          const net_order_amount = getValue()
          return (
            <span
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "100%",
              }}
            >
              ₹
              {net_order_amount
                ? `${parseFloat(net_order_amount).toFixed(2)}`
                : "0.00"}
              {/* {net_order_amount} Format to two decimal places */}
            </span>
          )
        },
      },
      {
        header: () => (
          <div style={{ textAlign: "right", width: "100%" }}>
            Pending Amount
          </div>
        ),
        accessorKey: "due_amount",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ getValue }) => {
          const net_order_amount = getValue()
          return (
            <span
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "100%",
              }}
            >
              ₹
              {net_order_amount
                ? `${parseFloat(net_order_amount).toFixed(2)}`
                : "0.00"}
              {/* {net_order_amount} Format to two decimal places */}
            </span>
          )
        },
      },

      {
        header: "Exp Payment Date",
        accessorKey: "expiry_date",
        // cell: ({ getValue }) => {
        //   const orderDate = getValue()
        //   return orderDate ? moment(orderDate).format("DD-MM-YYYY") : "" // Format the date
        // },
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Last Payment Date",
        accessorKey: "last_payment_date",
        // cell: ({ getValue }) => {
        //   const orderDate = getValue()
        //   return orderDate ? moment(orderDate).format("DD-MM-YYYY") : "" // Format the date
        // },
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [page]
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
        mainHeader="Are you sure you want to delete this client?"
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb
            title="Reports"
            breadcrumbItem="Customer Payment Failed Report"
          />

          {
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody className="border-bottom1">
                    <div className="d-flex align-items-center">
                      <h5 className="mb-0 card-title flex-grow-1">
                        Customer Payment Failed Report
                      </h5>
                      <div className="flex-shrink-0">
                        <Button
                          type="secondary"
                          onClick={() => {
                            if (advanceSearch.open) {
                              setPage(1)

                              getClientListRecord(false, true)
                            } else {
                              setAdvanceSearch({
                                ...advanceSearch,
                                open: advanceSearch.open ? false : true,
                              })
                            }
                          }}
                          id={`client-gloabal-search`}
                        >
                          <i className="mdi mdi-magnify" />

                          <UncontrolledTooltip
                            placement="top"
                            target={`client-gloabal-search`}
                          >
                            Search
                          </UncontrolledTooltip>
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    {/* Global Search */}
                    <Fade>
                      {advanceSearch.open && (
                        <Row>
                          <Col lg={3} className="mb-3">
                            <label>Client</label>
                            <Select
                              options={clientList} // Pass the clientTypeList with { label, value } structure
                              value={
                                clientList.find(
                                  option =>
                                    option.value === advanceSearch.client_id
                                ) || null
                              } // Set selected value
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  client_id: selectedOption?.value, // Update the state with the selected value
                                })
                              } // Update state on change
                              styles={customStyles(
                                false,
                                !advanceSearch.clientTypeId
                              )} // Use customStyles for consistent appearance
                              placeholder="Select" // Placeholder text
                              classNamePrefix="react-select" // For scoping styles
                              isSearchable={true} // Enable search functionality
                              isClearable={true}
                            />
                          </Col>
                          {/* <Col lg={3} className="mb-3">
                            <Label>Salesman</Label>
                            <Select
                              options={dummySalesmen}
                              value={
                                dummySalesmen.find(
                                  option =>
                                    option.value === advanceSearch.sales_man_id
                                ) || null
                              }
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  sales_man_id: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                              isClearable={true}
                            />
                          </Col> */}

                          <Col lg={3} className="mb-3">
                            <Label>Order Id</Label>
                            {/* <Select
                              options={dummyOrderIds}
                              value={dummyOrderIds.find(
                                option => option.value === advanceSearch.orderId
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  order_no: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            /> */}

                            <input
                              type="text"
                              onChange={e =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  order_no: e.target.value,
                                })
                              }
                              className="form-control"
                              value={advanceSearch.orderId}
                            />
                          </Col>
                          <Col lg={3} className="mb-3">
                            <label>Order Type</label>
                            <Select
                              options={clientTypeList} // Pass the clientTypeList with { label, value } structure
                              value={
                                clientTypeList.find(
                                  option =>
                                    option.value === advanceSearch.order_type
                                ) || null
                              } // Set selected value by finding it in the clientTypeList
                              onChange={selectedOption => {
                                // Update advanceSearch with the selected value
                                setAdvanceSearch(prevState => ({
                                  ...prevState,
                                  order_type: selectedOption?.value, // Update order_type in advanceSearch
                                }))
                              }}
                              styles={customStyles(
                                false,
                                !advanceSearch.order_type
                              )} // Use customStyles for consistent appearance
                              placeholder="Select" // Placeholder text
                              classNamePrefix="react-select" // For scoping styles
                              isSearchable={true} // Enable search functionality
                              isClearable={true} // Allow clearing selection
                            />
                          </Col>

                          <Col lg={3} className="mb-3">
                            <Label>Report Type</Label>
                            <Select
                              options={reportOptions}
                              value={reportOptions.find(
                                option => option.value === reportType
                              )}
                              onChange={selectedOption => {
                                setReportType(selectedOption?.value)
                              }}
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>

                          <Col lg={3} className="mb-3">
                            <Label>Date Range</Label>
                            <Select
                              options={periodOptions1}
                              value={
                                periodOptions1.find(
                                  option => option.value === choose
                                ) || null
                              }
                              onChange={selectedOption => {
                                setChoose(selectedOption?.value) // Update selected date range
                                setAdvanceSearch(prevState => ({
                                  ...prevState,
                                  order_start_date: null, // Reset start date
                                  order_end_date: null, // Reset end date
                                }))
                              }}
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                            />
                          </Col>

                          {/* <Col lg={3} className="mb-3">
                            <Label>AC Year</Label>
                            <Select
                              options={dummyAcYears}
                              value={dummyAcYears.find(
                                option => option.value === advanceSearch.acYear
                              )}
                              onChange={selectedOption =>
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  acYear: selectedOption?.value,
                                })
                              }
                              styles={customStyles(false)}
                              placeholder="Select"
                              classNamePrefix="react-select"
                              isSearchable={true}
                              isDisabled={true}
                            />
                          </Col> */}

                          {choose === "1" && (
                            <>
                              <Col lg={3} className="mb-3">
                                <label>Start Date</label>

                                <DatePicker
                                  date={advanceSearch.search}
                                  onDateChange={date => {
                                    // setRegDate(date)
                                    console.log(
                                      "Selected order_start_date:",
                                      date
                                    )
                                    setAdvanceSearch({
                                      ...advanceSearch,
                                      order_start_date:
                                        moment(date).format("YYYY-MM-DD"),
                                    })
                                  }}
                                />
                              </Col>
                              <Col lg={3} className="mb-3">
                                <label>End Date</label>

                                <DatePicker
                                  date={advanceSearch.search}
                                  onDateChange={date => {
                                    // setRegDate(date)
                                    console.log(
                                      "Selected order_end_date:",
                                      date
                                    )
                                    setAdvanceSearch({
                                      ...advanceSearch,
                                      order_end_date:
                                        moment(date).format("YYYY-MM-DD"),
                                    })
                                  }}
                                />
                              </Col>
                            </>
                          )}

                          {choose === "2" && (
                            <>
                              <Col lg={3} className="mb-3">
                                <Label>Period</Label>
                                <Select
                                  options={periodOptions}
                                  value={
                                    periodOptions.find(
                                      option => option.value === selectedPeriod
                                    ) || null
                                  }
                                  onChange={selectedOption =>
                                    handlePeriod(selectedOption?.value)
                                  }
                                  styles={customStyles(false)}
                                  placeholder="Select"
                                  classNamePrefix="react-select"
                                  isSearchable={true}
                                  isClearable={true}
                                />
                              </Col>
                            </>
                          )}
                          {choose === "3" && (
                            <>
                              <Col lg={3} className="mb-3">
                                <Label>A/C Year</Label>
                                <Select
                                  options={dummyAcYears}
                                  value={dummyAcYears.find(
                                    option =>
                                      option.value === advanceSearch.acYear
                                  )}
                                  onChange={selectedOption => {
                                    const acYear = selectedOption?.value
                                    let startDate, endDate

                                    if (acYear) {
                                      const [startYear, endYear] = acYear
                                        .split("-")
                                        .map(y => parseInt(y, 10))
                                      startDate = `${startYear}-04-01` // April 1 of start year
                                      endDate = `${2000 + endYear}-03-31` // March 31 of end year
                                    } else {
                                      startDate = null
                                      endDate = null
                                    }

                                    setAdvanceSearch({
                                      ...advanceSearch,
                                      order_start_date: startDate,
                                      order_end_date: endDate,
                                    })
                                  }}
                                  styles={customStyles(false)}
                                  placeholder="Select"
                                  classNamePrefix="react-select"
                                  isSearchable={true}
                                  isClearable={true}
                                />
                              </Col>
                            </>
                          )}
                          <Col
                            lg={
                              choose === null
                                ? 9
                                : choose === 1
                                ? 6
                                : choose === 2
                                ? 3
                                : 0
                            }
                            className="mb-4 d-flex align-items-end justify-content-end"
                          >
                            <Button
                              color="danger"
                              outline
                              className="me-1"
                              onClick={e => {
                                setPage(1)
                                setReportType("list")
                                setTrigger(0)
                                setChoose(null)
                                setGraphData()
                                setSelectedPeriod(null)
                                setRest(1)
                                setAdvanceSearch({
                                  ...advanceSearch,
                                  client_id: null, // Reset the client ID to null
                                  order_start_date: "",
                                  order_end_date: "",
                                  client_id: null,
                                  order_no: null,
                                  sales_man_id: null,
                                  open: false,
                                  order_type: null,
                                })
                                getClientListRecord(advanceSearch)
                              }}
                              id={`client-cancel`}
                            >
                              <i className="mdi mdi-close" />

                              <UncontrolledTooltip
                                placement="top"
                                target={`client-cancel`}
                              >
                                Reset
                              </UncontrolledTooltip>
                            </Button>
                            <Button
                              color="primary"
                              onClick={e => {
                                setPage(1)
                                handleSearch()
                                // getClientListRecord(true, false)
                              }}
                            >
                              <i className="mdi mdi-cloud-search" /> Search
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Fade>
                    {reportType === "summary" && trigger === 1 && (
                      <div>
                        {graphData?.series?.some(series =>
                          series.data.some(value => value > 0)
                        ) ? (
                          <Apexchart1 graphData={graphData} />
                        ) : (
                          <>
                            <div>No data available</div>{" "}
                          </>
                        )}
                      </div>
                    )}

                    {/* {reportType === "list" && !loading && (
                      <>
                        <div className="my-3 row">
                          <div className="col-md-4">
                            <div>
                              <strong>Number of Records:</strong>
                              <span className="mx-3">
                                {gstData?.total_record_count}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <strong>Total Order Amount: </strong>
                              <span className="mx-3">
                                ₹ {gstData?.total_sales || 0}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {reportType === "list" && (
                      <>
                        <TableContainer
                          columns={columns}
                          data={saleSearchData}
                          loading={loading}
                          setLoading={setLoading}
                          isGlobalFilter={false}
                          // isJobListGlobalFilter={true}
                          customPageSize={limit}
                          isPagination={false}
                          SearchPlaceholder="Search for ..."
                          tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline border-top"
                        />
                        {totalCount > 0 && (
                          <Paginations
                            perPageData={limit}
                            // data={clients}
                            data={saleSearchData || []}
                            currentPage={page}
                            setCurrentPage={setPage}
                            isShowingPageLength={!loading}
                            totalCount={totalCount}
                            isLimit={true}
                            limit={limit}
                            setLimit={setLimit}
                            paginationClass="pagination"
                            paginationDiv="dataTables_paginate paging_simple_numbers pagination-rounded server"
                          />
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Contact Details{" "}
          {!isEmpty(selectedItem) ? ` of ${selectedItem?.client_name}` : ""}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive"></div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  )
}

export default CustomerPaymentFailedReport
