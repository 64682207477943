import { del, get, patch, post } from "helpers/api_helper";

export const getSalesSearchList = async(params) => await get('/order/sell-summary',{params});
export const getSalesSearchGraph = async(params) => await get('/order/sell-summary-graph',{params});

// Total Summery
export const getGstTotal = async (params)  => await get('/order/gst-report-summery',{params})

// TDS Report 
export const getTdsData = async (params) =>await get('/order/tds-report',{params})

// Profit Cost Report
export const getProfitCostReportData = async (params) =>await get('/order/profit-cost-report',{params})

export const getSalesmanSearchList = async(params) => await get('/order/salesman-report',{params});

// Product Report
export const getProductReportData = async (params) =>await get('/order/product-report',{params})
// Customer
export const getCustomerReportList = async(params) => await get('/order/customer-report',{params});




// Customer-Payment Failed
export const getPaymentFailedList = async(params) => await get('/order/customer-payment-report',{params});


//Partial GST Report
export const getPartialGstReportList = async(params) => await get('/order/partial-gst-report',{params});
//Partial GST Report Summary
export const getPartialGstReportSummaryList = async(params) => await get('/order/partial-gst-report-summary',{params});