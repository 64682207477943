import React, { useEffect, useMemo, useState } from "react"

import {
    Col,
    Row,
    UncontrolledTooltip,
    Card,
    CardBody,
    Button,
    ModalHeader,
    Modal,
    ModalBody,
    CardTitle,
    UncontrolledAccordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Table,
    CardHeader,
    Input,
    Label,
    FormFeedback,
    Fade,
    Spinner,
    CardSubtitle,
    CardFooter,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
} from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { getSinglePaymentOrder } from "services/PaymentOrderServices"
import { formatDate } from "helpers/helpers"
import { getSettingsList } from "services/SettingsService"
import { isEmpty } from "lodash"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import useEventBus from "hooks/useEventBus";
import PraviiLogo from 'assets/images/main-logo.png';
const { ToWords } = require('to-words');


const ReceiptOrderView = () => {
    //meta title
    document.title = `Money Receipt |  ${process.env.REACT_APP_SITE_NAME} - Admin Panel`


    const navigate = useNavigate()
    const [mainLoading, setMainLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [paymentDetail, setPaymentDetail] = useState({})
    const [settings, setSettings] = useState(null)

    const { id } = useParams()
    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2],
    });

    const toWords = new ToWords();



    useEffect(() => {
        getPaymentDetailRecord();
        getSettingsData()
    }, []);


    const getPaymentDetailRecord = async () => {


        try {
            setMainLoading(true)

            await getSinglePaymentOrder(atob(id)).then(response => {
                console.log(response)
                if (response) {
                    setPaymentDetail(response)
                    setMainLoading(false)
                } else {
                    navigate(`/receipt-order`)
                    setMainLoading(false)
                }
            })
                .catch(err => console.log(err))

        } catch (error) {
            console.log(error)
            setMainLoading(false)
        }
    }

    const getSettingsData = async () => {
        try {
            const params = {
                setting_var: ["pan_no", "gst_no", "sac_no", "arn_no", "date_of_filing", "source_company_info"]
            }
            const response = await getSettingsList(params)
            setSettings(response)
        } catch (error) {
            console.log(error)
        }
    }


    const getSettingByKey = (key) => {
        if (!settings) return '-';
        const setting = settings.find(s => s.setting_var === key);
        return setting?.var_val || '-';
    }


    const generatePDF = async () => {
        setLoading(true)
        // Get the invoice element
        const invoice = document.getElementById('receiptTable');

        if (!invoice) {
            console.error("Receipt element not found");
            return;
        }

        try {
            // Create canvas from the invoice element
            const canvas = await html2canvas(invoice, {
                scale: 2, // Higher scale for better quality
                useCORS: true,
                logging: false,
                backgroundColor: '#ffffff'
            });

            // Get image data
            const imgData = canvas.toDataURL('image/jpeg', 1.0);

            // Calculate dimensions
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            // Create PDF
            const doc = new jsPDF('p', 'mm', 'a4');
            let position = 0;

            // Add image to first page
            doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // Add new pages if the content exceeds one page
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Open PDF in new window
            doc.output("dataurlnewwindow");
            setLoading(false)
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };


    return (
        <React.Fragment>


            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb
                        title="Payment"
                        to={`/receipt-order`}
                        breadcrumbItem="Money Receipt"
                    />
                    <Card>
                        <Row>
                            <Col lg="12">
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">
                                            Money Receipt
                                        </h5>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Link
                                                to={"/receipt-order"}
                                                className="me-2"

                                            >
                                                <i className="mdi mdi-arrow-left" /> Go Back
                                            </Link>
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="mdi mdi-dots-vertical" style={{ fontSize: 23 }}></i>
                                                </DropdownToggle>
                                                <DropdownMenu end style={{ padding: 0 }}>

                                                    <DropdownItem style={{ padding: '13px 10px' }}>
                                                        <a href="javascript:void(0)" onClick={generatePDF}>
                                                            <i className="mdi mdi-download" /> Download PDF
                                                        </a>
                                                    </DropdownItem>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </CardBody>
                            </Col>

                            <Fade>
                                {" "}
                                {mainLoading && (
                                    <div
                                        style={{
                                            background: "rgba(0,0,0,0.3)",
                                            position: "fixed",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 99999,
                                        }}
                                    >
                                        <Spinner
                                            color="primary"
                                            className="position-absolute top-50 start-50"
                                            style={{ zIndex: 99 }}
                                        />
                                    </div>
                                )}
                            </Fade>

                            {
                                !isEmpty(paymentDetail) &&
                                <Col lg={12}>

                                    <Card className="receiptbg"
                                    >
                                        {/* <CardHeader>      <button className="btn btn-primary float-end" onClick={generatePDF}>
                                            <i className="mdi mdi-download" /> Download PDF
                                        </button></CardHeader> */}
                                        <CardBody >
                                            <table style={{
                                                width: '90%',
                                                padding: '1%',
                                                margin: '5%',
                                                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                                                fontSize: '17px',
                                                lineHeight: '27px',
                                                color: '#474747',
                                                border: '1px solid #ccc',
                                                marginTop: 0,
                                                //   background: `url(${window.location.origin}/praaviifavicon.jpg) no-repeat center 20%`
                                            }} align="center" id="receiptTable">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table style={{ width: '94%', margin: '0% 3% 0px', marginTop: '2%' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{
                                                                            fontSize: '35px',
                                                                            fontWeight: 'bold',
                                                                            lineHeight: '38px',
                                                                            width: '33%',
                                                                            verticalAlign: 'bottom'
                                                                        }}>
                                                                            <img src={PraviiLogo} />
                                                                        </td>
                                                                        <td style={{ textAlign: 'center', width: '33%', verticalAlign: 'bottom' }}>
                                                                            <h1 style={{
                                                                                fontSize: '30px',
                                                                                paddingTop: '0px',
                                                                                margin: 0,
                                                                                color: '#1e1e1e',
                                                                                lineHeight: '37px',
                                                                                textDecoration: 'underline'
                                                                            }}>
                                                                                MONEY RECEIPT
                                                                            </h1>
                                                                        </td>
                                                                        <td style={{ textAlign: 'right', width: '33%', verticalAlign: 'bottom', lineHeight: '15px' }}>


                                                                            <p style={{ margin: 0, marginBottom: 5 ,    color: '#1e1e1e',fontWeight: '600' }}>   {isEmpty(paymentDetail?.money_receipt_no) ? "" : `${paymentDetail?.money_receipt_no}`}</p>
                                                                            <p style={{ margin: 0 }}>{formatDate(paymentDetail?.payment_date, "MMMM DD, YYYY")}</p>


                                                                        </td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3}>
                                                                            <div style={{ margin: '5% 0' }}></div>
                                                                        </td>
                                                                    </tr>
                                                                

                                                                    <tr>
                                                                        <td colSpan={2}>

                                                                        <h5 style={{ fontWeight: '600' }}>Praavii Boutique</h5>


                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                                                                                {getSettingByKey('source_company_info')}
                                                                            </p>
                                                                            {/* <br /> */}
                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                                                                                PAN NO. {getSettingByKey('pan_no')}
                                                                            </p>
                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                                                                                GSTIN: {getSettingByKey('gst_no')}
                                                                            </p>
                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>
                                                                                SAC: {getSettingByKey('sac_no')}
                                                                            </p>
                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 20 }}>
                                                                                LUT ARN: {getSettingByKey('arn_no')}
                                                                            </p>
                                                                        </td>
                                                                        <td style={{  verticalAlign: "baseline" }}>
                                                                            <p style={{ fontSize: '15px', lineHeight: '1.2rem' ,marginBottom:0}}>
                                                                                {/* <strong style={{ color: '#1e1e1e', marginBottom: '-14px', display: 'block' }}>To</strong>
                                                                                <br /> */}
                                                                                     <h5 style={{ fontWeight: '600' }}>
                                                            {paymentDetail?.client?.client_name}
                                                        </h5>
                                                                                {paymentDetail?.client?.client_name}<br />
                                                                                {paymentDetail?.client?.address}<br />
                                                                                {paymentDetail?.client?.country || '-'}
                                                                            </p>
                                                                       

                                                                            {
                                                                                paymentDetail?.client?.clientContact?.[0]?.ph_no1 &&
                                                                                <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>

                                                                              Contact No.:  { paymentDetail?.client?.clientContact?.[0]?.ph_no1??'' }
                                                                                </p>
                                                                            }

                                                                            {
                                                                                paymentDetail?.client?.pan_no &&
                                                                                <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>

                                                                                    PAN No.:  {paymentDetail?.client?.pan_no}
                                                                                </p>
                                                                            }
                                                                            
                                                                            {
                                                                                paymentDetail?.client?.gst_no &&
                                                                                <p style={{ fontSize: '15px', lineHeight: '1.2rem', whiteSpace: 'pre-wrap', marginBottom: 0 }}>

                                                                                    GSTIN: {paymentDetail?.client?.gst_no}
                                                                                </p>
                                                                            }


                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style={{ padding: '3%', paddingTop: 0 }}>



                                                            {/* {
                                                                paymentDetail?.paymentMethods?.method_name &&
                                                                <p style={{ fontSize: '15px', lineHeight: '1.2rem' }}>
                                                          
                                                                        Payment Method: {paymentDetail?.paymentMethods?.method_name}
                                                                </p>
                                                            }
*/}
                                                            <br /> 



                                                            <table style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr style={{
                                                                        background: '#e7e6e9',
                                                                        color: 'rgb(85, 85, 85)'
                                                                    }}>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            fontSize: '15px',
                                                                            fontWeight: '600'
                                                                        }}>
                                                                            Order Id
                                                                        </td>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            fontSize: '15px',
                                                                            fontWeight: '600'
                                                                        }}>
                                                                            Invoice No.
                                                                        </td>

                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            fontSize: '15px',
                                                                            fontWeight: '600'
                                                                        }}>
                                                                            Amount(₹)
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {paymentDetail?.paymentDetails && paymentDetail?.paymentDetails?.map((item, index) => (
                                                                        <tr key={item.id}>
                                                                            <td style={{
                                                                                padding: '10px 15px'
                                                                            }}>
                                                                                {item?.orders?.order_no}
                                                                            </td>
                                                                            <td style={{
                                                                                padding: '10px 15px'
                                                                            }}>
                                                                                {item?.orders?.invoice_no}
                                                                            </td>

                                                                            <td style={{
                                                                                padding: '10px 15px',
                                                                                textAlign: 'right'
                                                                            }}>
                                                                                {(Number(item?.amount) + Number(item?.absorb_charges)  + Number(item?.tds_charges)).toFixed(2)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                    <tr>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} colSpan={2}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                Total Charges(₹):
                                                                            </span>
                                                                        </td>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} >
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                {paymentDetail?.deducted_amount}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        paymentDetail?.tds_charges &&
                                                                    <tr>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} colSpan={2}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                Total TDS(₹):
                                                                            </span>
                                                                        </td>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} >
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                {paymentDetail?.tds_charges}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    }



                                                                    <tr>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} colSpan={2}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'bold'
                                                                            }}>
                                                                                TOTAL AMOUNT(₹):
                                                                            </span>
                                                                        </td>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'bold'
                                                                            }}>
                                                                                {paymentDetail?.amount}
                                                                            </span>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style={{
                                                                            padding: '10px 15px',
                                                                            // textAlign: 'right',
                                                                            borderTop: '2px solid #ccc',
                                                                            borderBottom: '2px solid #ccc'
                                                                        }} colSpan={2}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600',
                                                                                // fontStyle: "italic"
                                                                            }}>
                                                                                In words
                                                                            </span>

                                                                        </td>
                                                                        <td colSpan={2}

                                                                            style={{
                                                                                padding: '10px 15px',
                                                                                textAlign: 'right',
                                                                                borderTop: '2px solid #ccc',
                                                                                borderBottom: '2px solid #ccc',
                                                                                // fontStyle: "italic"
                                                                            }}>
                                                                            <span style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: '600'
                                                                            }}>
                                                                                {
                                                                                    toWords.convert(paymentDetail?.amount, { currency: true })
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                paymentDetail?.paymentMethods?.method_name &&
                                                                    <tr>
                                                                        <td style={{
                                                                           padding: '10px 15px',
                                                                            // borderTop: '2px solid #ccc',
                                                                            // borderBottom: '2px solid #ccc'
                                                                        }} colSpan={3}>
                                                                            <span >
                                                                                 Payment Method: {paymentDetail?.paymentMethods?.method_name}
                                                                            </span>

                                                                        </td>
                                                                      
                                                                    </tr>

                                                                            }
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </CardBody>

                                    </Card>
                                </Col>
                            }


                        </Row>
                    </Card>


                </div>
            </div>

            <ToastContainer />
        </React.Fragment>
    )
}

export default ReceiptOrderView
